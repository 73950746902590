import React from "react"
import Helmet from 'react-helmet';


import Navbar from "../components/Navbar";

import logo from '../images/logo.svg';
import style from "./Page.module.scss";

type PageProps = {
    children: React.ReactNode
    location: any
    subHeader?: string
}

export default ({ location, children, subHeader }: PageProps) => {

    return (
        <div className={style.root}>
            <Helmet title={subHeader ?? 'tarbot'} defer={false} link={[
                { rel: 'shortcut icon', type: 'image/svg', href: `${logo}` }
            ]}>
                <meta name={'viewport'} content={'width=device-width, initial-scale=1'}></meta>
            </Helmet>


            <Navbar location={location} subHeader={subHeader}></Navbar>
            { children }

        </div>
    );
}
