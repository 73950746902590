import React from 'react';
import classNames from 'classnames';
import { Link } from "gatsby";

import logo from '../images/logo.svg';

import * as style from "./Navbar.module.scss";


type NavbarProps = {
    subHeader?: string
    location: any
}
export default ( { location, subHeader }: NavbarProps) => {
    
    return (
        <nav className={style.root}>
            <div className={style.inner}>
                
                <div className={style.items}>
                    <Link to='/pnl' className={style.brand}> 
                        <img src={logo} />
                        <strong>Tarbot</strong>
                    </Link>
                    { subHeader && <span className={style.subHeader}>{subHeader}</span>}
                </div>
                
                <div className={classNames(style.items, style.itemsRight)}>
                   {/* <Link to={'/docs'} className={style.link}>Docs</Link> */}
                   {/* <Link to={'/subscription'} className={style.link}>Subscriptions</Link> */}
                   {/* <Link to={'/pnl'} className={style.link}>Profit Loss</Link> */}
                </div>

            </div>
        </nav>
    )
}